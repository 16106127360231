import React from "react";
import '../styles/shortForm.css';
const short1Page = () => (
    <div class="grid-container">
    <div class="left_space"></div>
    <div class="short1-main">
    <div class="long2-main1 shortpage-bg-image">
        <div className="logo-div">
                <a aria-current="page" className="logo" href="/"><div className="logo-image"></div>
                 </a></div>
                 <br></br>
                 </div>
        <div class="long2-main2">
            <h3 className="form-section-header register-form-section-header">Thanks for locking in your fixed toll rate offer!<br></br>Your discount has been automatically applied for the eligible vehicle(s) in the promotional email.</h3>
                        <a href='https://www.texpresslanes.com/faq-page' target='_blank'><p className="faq">MyTEXpress FAQs</p></a>
            </div>
    </div>  
    <div class="right_space"></div>
    <div class="footer">
            <div class="register-footer1"><a href="https://www.facebook.com/TEXpressLanes" target="_blank"><img class="fb-icon"
  src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"/></a> | <a href="https://twitter.com/texpresslanes" target="_blank"><img class="twitter-icon"
  src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"/></a> |  <a href="https://www.texpresslanes.com/privacy-policy" target="_blank">Privacy Policy</a> │ ©2025 LBJ/NTE/NTE 35W TEXpress Lanes | All Rights Reserved</div>
        </div>
</div>
);

export default short1Page;
